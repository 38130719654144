import {
  MiniHeader,
  NavScrollExample,
  MiniBanner,
  SucursalesFooter,
  LargeFooter,
  MiniFooter,
} from './Home';
import { Button, Row, Col } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../../styles/Servicios.scss';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import pointer from '../../assets/proximo.png';

function Servicios() {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    console.log('input: ', data.packageID);
    const trackingNumber = data.packageID;
    navigate(`/rastreo-details/${trackingNumber}`);
  };
  return (
    <div>
      <Helmet>
        <title>VDM | Servicios</title>
        {/* <meta
          name='description'
          content='Beginner friendly page for learning React Helmet.'
        /> */}
      </Helmet>
      <MiniHeader />
      <NavScrollExample />
      <Row className='row-main-banner servicio'>
        <Col md={6} sm={12}></Col>
        <Col md={6} sm={12} className='right-panel'>
          <p>Un servicio confiable</p>
          <h2>CALIDAD Y SEGURIDAD GARANTIZADAS</h2>
          <div className='card'>
            <p className='less-width'>
              Entregas eficientes en todo México.
              <br />
              <b>Confía en VDM para tus envíos</b>
            </p>
          </div>
        </Col>
      </Row>
      <Row className='txtbanner'>
        <Col md={12} sm={12} className='minibannerRastreo'>
          <h2>Nuestros servicios</h2>
          <p className='less-width'>
            Nuestras rastreo en tiempo real te ofrece información precisa y
            actualizada sobre tus envíos.
          </p>
          <div className='btns'>
            <a href='#content'>
              <img src={pointer} alt='apuntador-tracking' />
            </a>
          </div>
        </Col>
      </Row>
      {/* First img row */}
      <Row className='text-img-banner section-3 servicios' id='content'>
        <Row className='text-img-banner-row'>
          <Col className='background-img-1' sm={12} md={6}></Col>
          <Col className='text-img-banner-row-section-3' sm={12} md={6}>
            <div className='content'>
              {/* <p className='first-p'>
                Presencia en <b>150 ciudades</b>
              </p> */}
              <p className='scnd-p'>Recolección y entrega a domicilio</p>
              <p className='thrd-p'>
                Confía en nosotros para manejar el proceso de envío desde el
                principio hasta el final. Nuestro equipo altamente capacitado se
                encargará de recolectar tu mercancía directamente en tu
                ubicación y garantizar su entrega segura y puntual a cualquier
                destino dentro de nuestra amplia cobertura.
              </p>
              <Button
                className='btn-light-blue'
                onClick={() => navigate('/contacto')}
              >
                Contáctanos
              </Button>
            </div>
          </Col>
        </Row>
      </Row>
      {/* Second img row */}
      <Row className='text-img-banner section-3 servicios'>
        <Row className='text-img-banner-row'>
          <Col className='text-img-banner-row-section-3' sm={12} md={6}>
            <div className='content'>
              {/* <p className='first-p'>
                Presencia en <b>150 ciudades</b>
              </p> */}
              <p className='scnd-p'>Seguro de mercancía</p>
              <p className='thrd-p'>
                Entendemos la importancia de proteger tus envíos, ofrecemos un
                seguro de mercancía confiable y completo que brinda tranquilidad
                y protección en cada envío. Nuestra cobertura integral incluye
                daños, robos parciales o totales, así como extravíos, con un
                deducible del 30%. El seguro se basa en el valor declarado de tu
                mercancía, asegurando una cobertura adecuada para proteger tus
                envíos.
              </p>
              <Button
                className='btn-light-blue'
                onClick={() => navigate('/contacto')}
              >
                Saber más
              </Button>
            </div>
          </Col>
          <Col className='background-img-2' sm={12} md={6}></Col>
        </Row>
      </Row>
      {/* Third img row */}
      <Row className='text-img-banner section-3 servicios'>
        <Row className='text-img-banner-row'>
          <Col className='background-img-3' sm={12} md={6}></Col>
          <Col className='text-img-banner-row-section-3' sm={12} md={6}>
            <div className='content'>
              {/* <p className='first-p'>
                Presencia en <b>150 ciudades</b>
              </p> */}
              <p className='scnd-p'>Emplaye de mercancía</p>
              <p className='thrd-p'>
                Protegemos tus envíos con nuestro servicio de emplaye.
                Utilizamos material de empaque de alta calidad para asegurar la
                integridad de tus bultos y cajas durante el transporte. El
                servicio tiene un costo adicional y se adapta a tus necesidades
                específicas. Confía en nosotros para garantizar la seguridad de
                tus envíos.
              </p>
              {/* <Button className='btn-light-blue'>Contáctanos</Button> */}
            </div>
          </Col>
        </Row>
      </Row>
      {/* 4th img row */}
      <Row className='text-img-banner section-3 servicios'>
        <Row className='text-img-banner-row'>
          <Col className='text-img-banner-row-section-3' sm={12} md={6}>
            <div className='content'>
              {/* <p className='first-p'>
                Presencia en <b>150 ciudades</b>
              </p> */}
              <p className='scnd-p'>Envíos a Ocurre (CEDIS)</p>
              <p className='thrd-p'>
                Ofrecemos la opción de que los clientes puedan recoger sus
                envíos en nuestras instalaciones, brindando comodidad al evitar
                la espera durante el día para la entrega a domicilio. Este
                servicio se adapta a los tiempos y preferencias del cliente,
                permitiéndoles tener el control sobre la recogida de sus envíos.
                Contamos con Cedis Operativos en{' '}
                <span>Puebla, Ciudad de México, Querétaro y León.</span>
              </p>
              <Button
                className='btn-light-blue'
                onClick={() => navigate('/contacto')}
              >
                Contáctanos
              </Button>
            </div>
          </Col>
          <Col className='background-img-4' sm={12} md={6}></Col>
        </Row>
      </Row>
      {/* 5th img row */}
      <Row className='text-img-banner section-3 servicios'>
        <Row className='text-img-banner-row'>
          <Col className='background-img-5' sm={12} md={6}></Col>
          <Col className='text-img-banner-row-section-3' sm={12} md={6}>
            <div className='content'>
              {/* <p className='first-p'>
                Presencia en <b>150 ciudades</b>
              </p> */}
              <p className='scnd-p'>
                Retorno de evidencias físicas o digitales
              </p>
              <p className='thrd-p'>
                Ofrecemos un servicio completo de retorno de evidencias, tanto
                físicas como digitales. Esto incluye pruebas de entrega con
                facturas, remisiones u otros documentos relevantes que requieran
                firmas o sellos en papel físico. Además, también nos encargamos
                de recopilar evidencias electrónicas, como firmas electrónicas y
                geolocalización, que se generan a través de nuestro sistema.
              </p>
              <Button
                className='btn-light-blue'
                onClick={() => navigate('/contacto')}
              >
                Contáctanos
              </Button>
            </div>
          </Col>
        </Row>
      </Row>
      {/* 6th img row */}
      <Row className='text-img-banner section-3 servicios'>
        <Row className='text-img-banner-row'>
          <Col className='text-img-banner-row-section-3' sm={12} md={6}>
            <div className='content'>
              {/* <p className='first-p'>
                Presencia en <b>150 ciudades</b>
              </p> */}
              <p className='scnd-p'>Unidades especializadas de paquetería:</p>
              <p className='thrd-p'>
                Contamos con una amplia flota de unidades especializadas que se
                adaptan a tus necesidades de paquetería, mensajería y carga LTL.
                Nuestros vehículos incluyen motocicletas, camionetas tipo panel
                de 1.5 toneladas, camiones de 3.5 toneladas para tarimas y
                camiones de 12 toneladas para tarimas con volumen, especialmente
                diseñados para cumplir con los requerimientos de envíos a
                distintos destinos
              </p>
              <Button
                className='btn-light-blue'
                onClick={() => navigate('/contacto')}
              >
                Contáctanos
              </Button>
            </div>
          </Col>
          <Col className='background-img-6' sm={12} md={6}></Col>
        </Row>
      </Row>
      {/* <LargeFooter /> */}
      <SucursalesFooter />
      <hr style={{ margin: 0, opacity: 0.9 }} />
      <MiniFooter />
    </div>
  );
}

export default Servicios;
