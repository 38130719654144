import React, { useEffect, useRef, useState } from 'react';
import {
  MiniHeader,
  NavScrollExample,
  MiniBanner,
  LargeFooter,
  SucursalesFooter,
  MiniFooter,
} from './Home.jsx';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWhatsapp,
  faFacebook,
  faSquareInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import repartidor from '../../assets/contactanos/4228126.png';
import { Helmet } from 'react-helmet';
import '../../styles/Contact.scss';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { Oval } from 'react-loader-spinner';

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

function Contact() {
  const form = useRef();
  const { register, handleSubmit, reset } = useForm();
  // const onSubmit = (data) => console.log(data);
  const navigate = useNavigate();
  const [res, setRes] = useState();
  const [loader, setLoader] = useState(false);

  const sendEmail = () => {
    // e.preventDefault();

    emailjs
      .sendForm(
        'service_uklrk3b',
        'template_9172unh',
        form.current,
        '1fHzJtz4F7GUuplcs'
      )
      .then(
        (result) => {
          setRes(result.text);
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  useEffect(() => {
    // if (res !== '') {
    //   const timer = setTimeout(() => setLoader(true), 2000);
    //   return () => {
    //     setLoader(false);
    //     clearTimeout(timer);
    //   };
    reset({
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      message: '',
    });
    // }
  }, [res]);

  return (
    <div>
      <Helmet>
        <title>VDM | Contacto</title>
        {/* <meta
          name='description'
          content='Beginner friendly page for learning React Helmet.'
        /> */}
      </Helmet>
      <ScrollToTopOnMount />
      <MiniHeader />
      <NavScrollExample />
      <Row className='main-banner-contact'>
        <Col md={10} sm={12} style={{ padding: '0 2.8rem' }}>
          <h1>Contáctanos</h1>
          <p>
            Nuestros asesores estan atentos para atender cualquier duda y
            proveerte la informacion adicional que desees. ¡Estamos para
            servirte!
          </p>
          <Button onClick={() => navigate('/contacto')}>Contáctanos</Button>
        </Col>
      </Row>

      <Row className='wapp-section'>
        <Col md={8} sm={12}>
          <p>
            Pensando en tu comodidad, hemos habilitado{' '}
            <b>atencion via Whatsapp</b> donde podras resolver cualquier
            interrogante de forma personalizada.{' '}
          </p>
        </Col>
        <Col md={4} sm={12}>
          <Button>
            <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: '5px' }} />
            Whatsapp
          </Button>
        </Col>
      </Row>

      <Row className='from-contact'>
        <Col
          className='container-center'
          md={7}
          sm={10}
          style={{ margin: '0 auto' }}
        >
          {loader ? (
            <Oval
              height={80}
              width={80}
              color='#171b74'
              wrapperStyle={{ justifyContent: 'center', margin: '5rem' }}
              wrapperClass=''
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor='#0b1429'
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            <form ref={form} onSubmit={handleSubmit(sendEmail)}>
              <p>
                Para brindarte informacion personalizada compartenos tus datos y
                pronto nos pondremos en contacto contigo
              </p>
              <Row className='flex'>
                <Col>
                  <Form.Control
                    placeholder='Nombre'
                    aria-label='Nombre'
                    {...register('firstName')}
                  />
                </Col>
                <Col>
                  <Form.Control
                    placeholder='Apellido'
                    aria-label='Apellido'
                    {...register('lastName')}
                  />
                </Col>
              </Row>
              <Row className='flex margin-top'>
                <Col>
                  <Form.Control
                    placeholder='Telefono'
                    aria-label='Telefono'
                    {...register('phone')}
                  />
                </Col>
                <Col>
                  <Form.Control
                    placeholder='Correo'
                    aria-label='Correo'
                    {...register('email')}
                  />
                </Col>
              </Row>
              <Row>
                <Form.Control
                  aria-label='Correo'
                  as='textarea'
                  style={{ height: '100px' }}
                  {...register('message')}
                />
              </Row>
              <Button type='submit' className='submit'>
                Enviar
              </Button>
            </form>
          )}
        </Col>
      </Row>

      <Row className='text-img-banner'>
        <Col md={6} sm={12}>
          <div className='circle-img'>
            <img src={repartidor} alt='' />
          </div>
        </Col>
        <Col className='text-container' md={6} sm={12}>
          <div className='content'>
            <h2>Siempre disponibles para ti</h2>
            <div className='contact-data'>
              <div className='contact-data-row'>
                <div className='icon'>
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
                <div className='icon-text'>
                  <p className='no-margin'>Dirección</p>
                  <p>No.100 Avenida, Colonia, Ciudad.</p>
                </div>
              </div>
              <div className='contact-data-row'>
                <div className='icon'>
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <div className='icon-text'>
                  <p className='no-margin'>Numero de Telefono</p>
                  <p>987 654 321 | Atención al cliente</p>
                </div>
              </div>
              <div className='contact-data-row'>
                <div className='icon'>
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <div className='icon-text'>
                  <p className='no-margin'>Email</p>
                  <p>servicioaclientes@vdmlogistics.com.mx</p>
                </div>
              </div>
            </div>

            <div className='social-media'>
              <div className='icons'>
                <FontAwesomeIcon icon={faFacebook} />
                <FontAwesomeIcon icon={faSquareInstagram} />
                <FontAwesomeIcon icon={faLinkedin} />
              </div>
              <div>
                <Button className='btn-wapp'>
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    style={{ marginRight: '5px' }}
                  />{' '}
                  Whatsapp
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className='map-section'>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57504.789812589195!2d-100.31147926516755!3d25.735881105981118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662930bca7a0d03%3A0x1af2660b410f21a7!2sSan%20Nicol%C3%A1s%20de%20los%20Garza%2C%20Nuevo%20Leon!5e0!3m2!1sen!2smx!4v1683673689117!5m2!1sen!2smx'
          // height='150px'
          style={{ border: 0, height: '60vh', width: '100%', padding: 0 }}
          allowfullscreen=''
          loading='lazy'
          referrerpolicy='no-referrer-when-downgrade'
        ></iframe>
      </Row>

      <MiniBanner count={5} />
      {/* <LargeFooter /> */}
      <SucursalesFooter />
      <hr style={{ margin: 0, opacity: 0.9 }} />
      <MiniFooter />
    </div>
  );
}

export default Contact;
