import {
  MiniHeader,
  NavScrollExample,
  LargeFooter,
  MiniFooter,
  SucursalesFooter,
  TextImgBanner,
} from './Home';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Row, Col, Form, Container } from 'react-bootstrap';
import '../../styles/Rastreo.scss';
import { useNavigate, useParams } from 'react-router-dom';
import loc from '../../assets/apartado-rastreo/pasador-de-ubicacion.png';
import { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { set } from 'react-hook-form';
import moment from 'moment';
import esLocale from 'moment/locale/es';
// import { Helmet } from 'react-helmet';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import Accordion from 'react-bootstrap/Accordion';
import { Buffer } from 'buffer';
import GoogleMap from 'google-map-react';
// import xtype from 'xtypejs';

function RastreoDetails() {
  moment.locale('es', [esLocale]);
  const navigate = useNavigate();
  const searchparams = useParams();
  const [resData, setData] = useState();
  const [lastMovement, setLastMovement] = useState();
  const [loader, setLoader] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    // const pattern = /[, ]/g;
    const arr = `${searchparams.packageid}`.split(', ').map((i, item) => ({
      Guia: i,
    }));
    if (arr.length > 1) {
      const fetchFnc = async () => {
        const credentials = Buffer.from('ASDPRUEBA:C0$mos1999').toString(
          'base64'
        );
        const options = {
          method: 'POST',
          headers: {
            Authorization: `Basic ${credentials}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(arr),
        };
        const res = await (
          await fetch('https://clientes-vdm.tpak.mx/ws/api/Rastreo', options)
        ).json();
        if (res.length !== arr.length) {
          setData(res[0]);
        } else {
          setData(res);
        }
        const timer = setTimeout(() => setLoader(true), 2500);
        return () => {
          setLoader(false);
          clearTimeout(timer);
        };
      };
      fetchFnc();
    } else {
      const dataFetch = async () => {
        var requestOptions = {
          method: 'GET',
          redirect: 'follow',
        };
        const data = await (
          await fetch(
            `https://clientes-vdm.tpak.mx/ws/api/Rastreo?guias=${searchparams.packageid}`,
            // `https://clientes-vdm.tpak.mx/ws/api/Rastreo?guias=2100000573880015,288147400015`,
            requestOptions
          )
        ).json();
        setData(data[0]);
        const timer = setTimeout(() => setLoader(true), 2500);
        return () => {
          setLoader(false);
          clearTimeout(timer);
        };
      };
      dataFetch();
    }

    // Guides to test
    // Con Entrega
    // 288146730016
    // 290010220017
    // 290005170011
    // 288147450010
    // 288147240015
    // 288147400015
    // Sin entrega
    // 288147150016
    // 288147160015
    // 288147080015
    // 288147210018
  }, [searchparams.packageid]);
  console.log('data:', resData);
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };
  return (
    <div>
      {/* <Helmet>
        <title>VDM | Rastreo</title>
      </Helmet> */}
      <MiniHeader />
      <NavScrollExample />
      <Row className='row-main-banner rastreo-details'>
        <Col md={6} sm={12}></Col>
        <Col md={6} sm={12} className='right-panel'>
          {/* <p>Envío rápido, seguro y confiable</p> */}
          <h2>Tus envíos a tu alcance</h2>
          <p className='less-width'>
            Nuestro rastreo en tiempo real te ofrece información precisa y
            actualizada sobre tus envíos.
          </p>
          <div className='btns'>
            {/* <Button className='btn-aboutus'>Conócenos</Button> */}
            <Button
              className='btn-light-blue'
              onClick={() => navigate('/contact')}
            >
              Contáctanos
            </Button>
          </div>
        </Col>
      </Row>

      <Row className='blue-banner rastreo center'>
        <h2>
          <b>Resultado del rastreo</b>
        </h2>
      </Row>

      <Container className='mobile-container'>
        {loader ? (
          <Row className='input-tracking-details'>
            {resData === undefined ? (
              <Col
                md={12}
                sm={10}
                style={{ textAlign: 'center', padding: '2rem 0' }}
              >
                <i>Informacion no encontrada</i>
              </Col>
            ) : resData.length > 1 ? (
              <>
                <Accordion>
                  {resData.map((data, i) => {
                    return (
                      <Accordion.Item eventKey={i}>
                        <Accordion.Header>
                          <span>Detalles de guia #</span>{' '}
                          <span className='blue'> {data.guia}</span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <>
                            <Row className='first-row'>
                              <Col md={6} sm={12}>
                                <span>
                                  Envio Nº{' '}
                                  {data.guia === '' ? (
                                    <i>Dato sin agregar</i>
                                  ) : (
                                    data.guia
                                  )}
                                </span>
                              </Col>
                              <Col md={6} sm={12} className='text-right'>
                                <span>
                                  Realizado el{' '}
                                  {data.fEntrega === null ? (
                                    <i>Dato sin agregar</i>
                                  ) : (
                                    moment(data.fEntrega).format('DD/MM/YYYY')
                                  )}
                                </span>
                              </Col>
                            </Row>

                            <Row className='second-row'>
                              <Col md={3} sm={12} className='card'>
                                <div>
                                  <span>Enviado desde:</span>
                                  <p>
                                    {data.ciudadOrigen !== '' ? (
                                      data.ciudadOrigen
                                    ) : (
                                      <i>Dato sin agregar</i>
                                    )}
                                  </p>
                                </div>
                                <div>
                                  <span>Con destino a:</span>
                                  <p>
                                    {data.ciudadDestino !== '' ? (
                                      data.ciudadDestino
                                    ) : (
                                      <i>Dato sin agregar</i>
                                    )}
                                  </p>
                                </div>
                                <div>
                                  <span>Fecha próxima de entrega:</span>
                                  <p className='no-margin'>
                                    {moment(data.fEntrega).format('DD/MM/YYYY')}
                                  </p>
                                </div>
                              </Col>
                              <Col md={8} sm={12} className='details'>
                                <div className='title'>
                                  <p>Detalles del envío:</p>
                                </div>
                                <Row>
                                  <Col md={6}>
                                    <span>Estatus actual:</span>
                                    <p>
                                      {data.estatus !== '' ? (
                                        data.estatus
                                      ) : (
                                        <i>Dato sin agregar</i>
                                      )}
                                    </p>
                                  </Col>
                                  <Col md={6}>
                                    <span>Peso total:</span>
                                    <p>
                                      {data?.dimensiones?.peso
                                        ? data?.dimensiones?.peso + 'KG'
                                        : '0KG'}
                                    </p>
                                  </Col>
                                </Row>
                                <div>
                                  <span>Dimensiones</span>
                                  {data?.dimensiones?.alto === null ? (
                                    <p>
                                      <i>Datos sin agregar</i>
                                    </p>
                                  ) : (
                                    <p>
                                      {data?.dimensiones?.alto} x{' '}
                                      {data?.dimensiones?.ancho} x{' '}
                                      {data?.dimensiones?.largo} cm. |{' '}
                                      {data?.dimensiones?.alto} x{' '}
                                      {data?.dimensiones?.ancho} x{' '}
                                      {data?.dimensiones?.largo} cm.
                                    </p>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className='row-pkg-details'>
                              <h2>Detalles del envío</h2>
                              {/* Map this element to register details of tracking */}
                              {/* {console.log('movs: ', data.movimientos)} */}
                              {data?.movimientos?.length === 0 ? (
                                <p
                                  style={{
                                    fontStyle: 'italic',
                                    color: '#171b74',
                                  }}
                                >
                                  No hay movimientos de rastreo registrados
                                </p>
                              ) : (
                                data?.movimientos?.map((item, i) => {
                                  // data?.movimientos?.findLast((value, i) => {
                                  //   console.log('know last item: ', value);
                                  // });

                                  return (
                                    <Col
                                      md={11}
                                      sm={11}
                                      style={{ marginTop: '1rem' }}
                                      content='center'
                                    >
                                      <p className='dateDetail'>
                                        <FontAwesomeIcon
                                          icon={faCircleCheck}
                                          style={{
                                            color: 'transparent',
                                            marginRight: '10px',
                                          }}
                                        />
                                        {/* Miercoles 24 de mayo, 2023 */}
                                        {moment(item.fMovimiento).format('LL')}
                                      </p>
                                      <p className='descDetail'>
                                        <FontAwesomeIcon
                                          icon={faCircleCheck}
                                          style={{
                                            color: '#14DE55',
                                            marginRight: '10px',
                                          }}
                                        />
                                        {/* Envío ingresado en sistema - CDMX */}
                                        {item.movimiento} - {item.oficina}
                                      </p>
                                    </Col>
                                  );
                                })
                              )}
                            </Row>
                            <Row className='third-row'>
                              <div className='location'>
                                <span>
                                  Ubicación actual (Oficina){' '}
                                  <b>{data.ciudadDestino}</b>
                                </span>
                                <span>
                                  {' '}
                                  Estatus actual: <b>{data.estatus}</b>
                                </span>
                              </div>
                              <div style={{ height: '50vh', width: '100%' }}>
                                <GoogleMap
                                  bootstrapURLKeys={{
                                    key: 'AIzaSyDXkV1aMggivto1SqVb79ZbC3Fsyds9e_s',
                                  }}
                                  defaultCenter={defaultProps.center}
                                  defaultZoom={defaultProps.zoom}
                                ></GoogleMap>
                              </div>
                              <Row className='doubts-row'>
                                <Col md={8} className='txt'>
                                  <p>
                                    ¿Existe algún problema? Para aclarar dudas o
                                    reportar alguna irregularidad comuníquese
                                    con nuestro centro de atención al cliente{' '}
                                  </p>
                                </Col>
                                <Col md={3} className='btn'>
                                  <Button
                                    className='btn-light-blue'
                                    onClick={() =>
                                      navigate('/rastreo-details/1')
                                    }
                                  >
                                    Atención al cliente
                                  </Button>
                                </Col>
                              </Row>
                            </Row>
                          </>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </>
            ) : (
              <>
                <Row className='first-row'>
                  <Col md={6} sm={12}>
                    <span>
                      Envio Nº{' '}
                      {resData.guia === '' ? (
                        <i>Dato sin agregar</i>
                      ) : (
                        resData.guia
                      )}
                    </span>
                  </Col>
                  <Col md={6} sm={12} className='text-right'>
                    <span>
                      Realizado el{' '}
                      {resData.fEntrega === null ? (
                        <i>Dato sin agregar</i>
                      ) : (
                        moment(resData.fEntrega).format('DD/MM/YYYY')
                      )}
                    </span>
                  </Col>
                </Row>

                <Row className='second-row'>
                  <Col md={3} sm={12} className='card'>
                    <div>
                      <span>Enviado desde:</span>
                      <p>
                        {resData.ciudadOrigen !== '' ? (
                          resData.ciudadOrigen
                        ) : (
                          <i>Dato sin agregar</i>
                        )}
                      </p>
                    </div>
                    <div>
                      <span>Con destino a:</span>
                      <p>
                        {resData.ciudadDestino !== '' ? (
                          resData.ciudadDestino
                        ) : (
                          <i>Dato sin agregar</i>
                        )}
                      </p>
                    </div>
                    <div>
                      <span>Fecha próxima de entrega:</span>
                      <p className='no-margin'>
                        {moment(resData.fEntrega).format('DD/MM/YYYY')}
                      </p>
                    </div>
                  </Col>
                  <Col md={8} sm={12} className='details'>
                    <div className='title'>
                      <p>Detalles del envío:</p>
                    </div>
                    <Row>
                      <Col md={6}>
                        <span>Estatus actual:</span>
                        <p>
                          {resData.estatus !== '' ? (
                            resData.estatus
                          ) : (
                            <i>Dato sin agregar</i>
                          )}
                        </p>
                      </Col>
                      <Col md={6}>
                        <span>Peso total:</span>
                        <p>
                          {resData?.dimensiones?.peso
                            ? resData?.dimensiones?.peso
                            : '0KG'}
                        </p>
                      </Col>
                    </Row>
                    <div>
                      <span>Dimensiones</span>
                      {resData?.dimensiones?.alto === null ? (
                        <p>
                          <i>Datos sin agregar</i>
                        </p>
                      ) : (
                        <p>
                          {resData?.dimensiones?.alto} x{' '}
                          {resData?.dimensiones?.ancho} x{' '}
                          {resData?.dimensiones?.largo} cm. |{' '}
                          {resData?.dimensiones?.alto} x{' '}
                          {resData?.dimensiones?.ancho} x{' '}
                          {resData?.dimensiones?.largo} cm.
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className='row-pkg-details'>
                  <h2>Detalles del envío</h2>
                  {/* Map this element to register details of tracking */}
                  {/* {console.log('movs: ', resData.movimientos)} */}
                  {resData?.movimientos?.length === 0 ? (
                    <p
                      style={{
                        fontStyle: 'italic',
                        color: '#171b74',
                      }}
                    >
                      No hay movimientos de rastreo registrados
                    </p>
                  ) : (
                    resData?.movimientos?.map((item, i) => {
                      return (
                        <Col
                          md={11}
                          sm={11}
                          style={{ marginTop: '1rem' }}
                          content='center'
                        >
                          <p className='dateDetail'>
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              style={{
                                color: 'transparent',
                                marginRight: '10px',
                              }}
                            />
                            {/* Miercoles 24 de mayo, 2023 */}
                            {moment(item.fMovimiento).format('LL')}
                          </p>
                          <p className='descDetail'>
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              style={{
                                color: '#14DE55',
                                marginRight: '10px',
                              }}
                            />
                            {/* Envío ingresado en sistema - CDMX */}
                            {item.movimiento} - {item.oficina}
                          </p>
                        </Col>
                      );
                    })
                  )}
                </Row>
                {resData.estatus === 'ENTREGADA' ? (
                  <Row className='third-row'>
                    <div className='location'>
                      <span>
                        Ubicación de entrega (Aproximada){' '}
                        <b>
                          {resData.estadoDestino} - {resData.paisDestino}
                        </b>
                      </span>
                      <span>
                        {resData?.entrega?.latitud},{' '}
                        {resData?.entrega?.longitud}
                      </span>
                    </div>
                    <div>
                      <iframe
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57504.787009457265!2d-100.27027955!3d25.7358869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662eb2925c219b5%3A0xe51d565382def4fc!2sFerretera%20Centenario%20de%20Monterrey!5e0!3m2!1ses-419!2smx!4v1685151492226!5m2!1ses-419!2smx'
                        width='100%'
                        height='450'
                        style={{ border: 0, borderRadius: '10px' }}
                        allowfullscreen=''
                        loading='lazy'
                        referrerpolicy='no-referrer-when-downgrade'
                      ></iframe>
                    </div>
                    <Row className='doubts-row'>
                      <Col md={8} className='txt'>
                        <p>
                          ¿Existe algún problema? Para aclarar dudas o reportar
                          alguna irregularidad comuníquese con nuestro centro de
                          atención al cliente{' '}
                        </p>
                      </Col>
                      <Col md={3} className='btn'>
                        <Button
                          className='btn-light-blue'
                          onClick={() => navigate('/rastreo-details/1')}
                        >
                          Atención al cliente
                        </Button>
                      </Col>
                    </Row>
                  </Row>
                ) : (
                  ''
                )}
              </>
            )}
          </Row>
        ) : (
          <Oval
            height={80}
            width={80}
            color='#171b74'
            wrapperStyle={{ justifyContent: 'center', margin: '5rem' }}
            wrapperClass=''
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor='#0b1429'
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        )}
      </Container>
      <TextImgBanner section={1} />
      {/* <LargeFooter /> */}
      <SucursalesFooter />
      <hr style={{ margin: 0, opacity: 0.9 }} />
      <MiniFooter />
    </div>
  );
}

export default RastreoDetails;
