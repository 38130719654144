import {
  MiniHeader,
  NavScrollExample,
  LargeFooter,
  MiniFooter,
  SucursalesFooter,
  TextImgBanner,
} from './Home';
import { Button, Row, Col, Form, Container } from 'react-bootstrap';
import '../../styles/Rastreo.scss';
import { useNavigate } from 'react-router-dom';
import loc from '../../assets/apartado-rastreo/pasador-de-ubicacion.png';
import pointer from '../../assets/proximo.png';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';

function Rastreo() {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    console.log('input: ', data.packageID);
    const trackingNumber = data.packageID;
    navigate(`/rastreo-details/${trackingNumber}`);
  };
  return (
    <div>
      <Helmet>
        <title>VDM | Rastreo</title>
        {/* <meta
          name='description'
          content='Beginner friendly page for learning React Helmet.'
        /> */}
      </Helmet>
      <MiniHeader />
      <NavScrollExample />
      <Row className='row-main-banner rastreo'>
        <Col md={12} sm={12} className='minibannerRastreo'>
          <h2>Rastrea tu paquete</h2>
          <p className='less-width'>
            Nuestras rastreo en tiempo real te ofrece información precisa y
            actualizada sobre tus envíos.
          </p>
          <div className='btns'>
            <a href='#rastrea'>
              <img src={pointer} alt='apuntador-tracking' />
            </a>
          </div>
        </Col>
      </Row>
      <Row className='input-tracking' id='rastrea'>
        <div className='description'>
          <div className='icon-loc'>
            <img src={loc} alt='' />
          </div>
          <h3>
            Para realizar la búsqueda ingrese el código de rastreo de su envío
          </h3>
          <p>Ubicado en la parte superior de su factura</p>
          <p className='blue'>
            <u>IMPORTANTE:</u> Si desea hacer una busqueda de{' '}
            <u>multiples guias</u>, favor de separarlas por coma y espacio{' '}
            <u>(ej. ABC-1233300, ABC-1233300)</u>
          </p>
        </div>
        <Col sm={12} md={7} className='form-container'>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Control
              placeholder='Ejemplo: ABC-1233300'
              aria-label='Search'
              {...register('packageID')}
            />
            <Button className='btn-blue' type='submit'>
              Rastrear
            </Button>
          </Form>
        </Col>
      </Row>
      <TextImgBanner section={1} />
      {/* <LargeFooter /> */}
      <SucursalesFooter />
      <hr style={{ margin: 0, opacity: 0.9 }} />
      <MiniFooter />
    </div>
  );
}

export default Rastreo;
