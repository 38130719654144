import {
  MiniHeader,
  NavScrollExample,
  LargeFooter,
  MiniFooter,
  SucursalesFooter,
  TextImgBanner,
} from './Home';
import { Button, Row, Col, Form, Container } from 'react-bootstrap';
import '../../styles/Rastreo.scss';
import { useNavigate, useRouteError } from 'react-router-dom';
import error_img from '../../assets/busqueda_failed.png';
import pointer from '../../assets/proximo.png';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';

function ErrorBoundary() {
  const error = useRouteError();
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>VDM | Rastreo</title>
        {/* <meta
          name='description'
          content='Beginner friendly page for learning React Helmet.'
        /> */}
      </Helmet>
      <MiniHeader />
      <NavScrollExample />
      <Row className='rastreo-failed'>
        <Col md={12} sm={12} className='minibannerRastreo'>
          <div className='icon-loc-failed'>
            <img src={error_img} alt='' />
          </div>
          <h2>Error en la busqueda</h2>
          <p className='less-width'>
            Para realizar la búsqueda ingrese el código de rastreo de su envío
            ubicado en la parte superior de su factura
          </p>
          <p className='blue'>
            <u>IMPORTANTE:</u> Si desea hacer una busqueda de{' '}
            <u>multiples guias</u>, favor de separarlas por coma y espacio{' '}
            <u>(ej. ABC-1233300, ABC-1233300)</u>
          </p>
          <div className='btns'>
            <Button
              className='btn-light-blue'
              onClick={() => navigate('/rastreo')}
            >
              Rastrea tu paquete
            </Button>
          </div>
        </Col>
      </Row>
      {/* <TextImgBanner section={1} /> */}
      {/* <LargeFooter /> */}
      <SucursalesFooter />
      <hr style={{ margin: 0, opacity: 0.9 }} />
      <MiniFooter />
    </div>
  );
}

export default ErrorBoundary;
