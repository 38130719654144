import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faSquareInstagram,
  faLinkedin,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import '../../styles/global_styles.scss';
import '../../styles/Home.scss';
import {
  Button,
  Container,
  Nav,
  Navbar,
  Form,
  Row,
  Col,
  InputGroup,
} from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
// images
import logo from '../../assets/LOGO.jpg';
import caja from '../../assets/enviar.png';
import costo from '../../assets/costo.png';
import verificado from '../../assets/verificado.png';
import cliente from '../../assets/servicio-al-cliente.png';
import manos from '../../assets/apreton-de-manos.png';
import cajaEntregaWhite from '../../assets/caja-de-entrega-white.png';
import cajaEntrega from '../../assets/caja-de-entrega2.png';
import domicilioWhite from '../../assets/entrega-a-domicilio-white.png';
import domicilio from '../../assets/entrega-a-domicilio2.png';
import paqueteWhite from '../../assets/paquete-withe.png';
import paquete from '../../assets/555.png';
import protegerWhite from '../../assets/proteger-white.png';
import proteger from '../../assets/proteger.png';
import sobreWhite from '../../assets/sobre-white.png';
import sobre from '../../assets/sobre.png';
import transporteWhite from '../../assets/transporte-white.png';
import transporte from '../../assets/transporte2.png';
import logoTransparent from '../../assets/Logo-Claro.png';
import logoTransparentBlue from '../../assets/Logo-azul-trans.png';
// end images
import {
  faCalendar,
  faEnvelope,
  faUser,
} from '@fortawesome/free-regular-svg-icons';
import {
  faArrowRight,
  faCertificate,
  faLocation,
  faLocationDot,
  faPhone,
  faSuitcase,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export function MiniHeader() {
  return (
    <header className='mini-header'>
      <div className='mail-phone'>
        <p>
          <FontAwesomeIcon icon={faEnvelope} className='iconEnvelope' />
          servicioaclientes@vdmlogistics.com <span className='barSpace'>
            |
          </span>{' '}
          <FontAwesomeIcon icon={faPhone} className='iconPhone' /> 987 654 321
        </p>
      </div>
      <div className='social-media'>
        <FontAwesomeIcon icon={faFacebook} />
        <FontAwesomeIcon icon={faSquareInstagram} />
        <FontAwesomeIcon icon={faLinkedin} />
      </div>
    </header>
  );
}

export function NavScrollExample() {
  return (
    <Navbar expand='lg' className='bg-light-rewrite'>
      <Container>
        <Navbar.Brand href='#' className='menu-logo'>
          <img src={logo} alt='' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='navbarScroll' />
        <Navbar.Offcanvas id='navbarScroll'>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id='offcanvasNavbarLabel-expand'>
              <img src={logo} alt='' style={{ width: '50%' }} />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className='me-auto my-2 my-lg-0 menu-items'>
              <Nav.Link href={'/'}>Inicio</Nav.Link>
              <Nav.Link href={'/servicios'}>Servicios</Nav.Link>
              <Nav.Link href={'/cobertura'}>Cobertura</Nav.Link>
              <Nav.Link href={'/rastreo'}>Rastreo</Nav.Link>
              <Nav.Link href='#action2' disabled>
                Cotizador
              </Nav.Link>
              <Nav.Link href={'/contacto'}>Contacto</Nav.Link>
            </Nav>
            <hr />
            <div className='mini-header-navbar'>
              <div className='mail-phone'>
                <p>
                  <FontAwesomeIcon icon={faEnvelope} className='iconEnvelope' />
                  servicioaclientes@vdmlogistics.com.mx
                </p>
                <p>
                  <FontAwesomeIcon icon={faPhone} className='iconPhone' /> 987
                  654 321
                </p>
              </div>
              <div className='social-media'>
                <FontAwesomeIcon icon={faFacebook} />
                <FontAwesomeIcon icon={faSquareInstagram} />
                <FontAwesomeIcon icon={faLinkedin} />
              </div>
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export function MainBanner() {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    // console.log('input: ', data.packageID);
    const trackingNumber = data.packageID;
    navigate(`/rastreo-details/${trackingNumber}`);
  };

  return (
    // <Container className="container-main-banner">
    <Row className='row-main-banner'>
      <Col md={6} sm={6} className='right-panel'>
        <h2>SOLUCIONES EN LOGÍSTICAS Y ENVÍOS</h2>
        <p>
          Comprometidos para satisfacer las necesidades de nuestros clientes, a
          través de un servicio confiable, seguro, rápido y amable
        </p>
        <div className='btns'>
          <Button
            className='btn-aboutus'
            onClick={() => navigate('/servicios')}
          >
            Conócenos
          </Button>
          <Button
            className='btn-light-blue'
            onClick={() => navigate('/contacto')}
          >
            Contáctanos
          </Button>
        </div>
      </Col>
      <Col md={4} sm={6} className='box-tracking'>
        <h3>Rastreo de paquete</h3>
        <p className='capitals'>
          Para REALIZAR LA BÚSQUEDA Ingrese EL CÓDIGO DE RAsTREO DE SU ENVÍO
        </p>
        <p style={{ color: '#677fbc' }}>
          Ubicado en la parte superior de su factura
        </p>
        <Form className='form-tracking' onSubmit={handleSubmit(onSubmit)}>
          {/* <Form.Control type='search' className='me-2' /> */}
          <Form.Control
            placeholder='Ejemplo: ABC-1233300'
            aria-label='Search'
            {...register('packageID')}
          />
          <Button className='btn-blue' type='submit'>
            Rastrear
          </Button>
          <a className='notfount-link' href='#'>
            No lo encuentro
          </a>
        </Form>
      </Col>
    </Row>
    // </Container>
  );
}

export function MiniBanner({ count }) {
  return (
    <Row className='main-mini-banner'>
      {count === 5 ? (
        <div className='items-banner bg-blue'>
          <Col className='space-left'></Col>
          <Col className='box'>
            <img className='prepago' src={caja} alt='' />
            <p>
              <b>Hacer</b> un <b>envío</b>
            </p>
          </Col>
          <Col className='box'>
            <img src={verificado} alt='' />
            <p>
              <b>Prepago</b>
            </p>
          </Col>
          <Col className='box'>
            <img className='costo' src={costo} alt='' />
            <p>
              <b>Cotizar</b>
            </p>
          </Col>
          <Col className='box'>
            <img className='atencion' src={cliente} alt='' />
            <p>
              <b>Atención</b> al <b>cliente</b>
            </p>
          </Col>
          <Col className='box'>
            <img className='manos' src={manos} alt='' />
            <p>
              <b>Convenio</b> de <b>servicio</b>
            </p>
          </Col>
          <Col className='space-right'></Col>
        </div>
      ) : (
        <div className='items-banner bg-light-blue'>
          <Col></Col>
          <Col className='box'>
            <FontAwesomeIcon icon={faUser} />
            <p className='label'>Clientes satisfechos</p>
            <p className='secondary-label'>1,150</p>
          </Col>
          <Col className='box'>
            <FontAwesomeIcon icon={faCertificate} />
            <p className='label'>Certificados de calidad</p>
            <p className='secondary-label'>18</p>
          </Col>
          <Col className='box'>
            <FontAwesomeIcon icon={faSuitcase} />
            <p className='label'>Años de experiencia</p>
            <p className='secondary-label'>+5</p>
          </Col>
          <Col className='box'>
            <FontAwesomeIcon icon={faCalendar} />
            <p className='label'>Proyectos completados</p>
            <p className='secondary-label'>253</p>
          </Col>
          <Col></Col>
        </div>
      )}
    </Row>
  );
}

export function TextImgBanner() {
  return (
    // <Container className='text-img-banner'>
    <Row className='text-logo-banner text-logo-banner-row rastreo'>
      <Col md={6} sm={12} className='text-logo-banner-col'>
        <p className='uppercase'>
          Profesionales en <b>Mensajería, Paquetería y LTL.</b>
        </p>
        <p className='description'>
          Contamos con Cedis Operativos en Puebla, Ciudad de México, Querétaro y
          León. Comprometidos para satisfacer las necesidades de nuestros
          clientes, a través de un{' '}
          <span className='blue-txt'>
            servicio confiable, seguro, rápido y amable.
          </span>
        </p>
      </Col>
      <Col md={4} sm={12} className='text-logo-banner-img'>
        <img src={logoTransparentBlue} alt='' />
      </Col>
    </Row>
    // </Container>
  );
}

export function ServiceHomeSection() {
  const navigate = useNavigate();

  return (
    <div className='service-home-secction'>
      <Container className='service-home-secction-container'>
        <Row className='service-home-secction-title'>
          <h6>Buscando satisfacer tus necesidades, te presentamos</h6>
          <h1>Nuestros Servicios</h1>
          <span className='blue-line-decorator'></span>
        </Row>
        <Row className='service-blocks first'>
          <Col className='box-service serv1'>
            <div className='gradient-hover'>
              <h4>RECOLECCIÓN Y ENTREGA A DOMICILIO</h4>
              <Button className='btn'>Saber más</Button>
            </div>
          </Col>
          <Col className='box-service serv2'>
            <div className='gradient-hover'>
              <h4>SEGURO DE MERCANCÍA</h4>
              <Button className='btn'>Saber más</Button>
            </div>
          </Col>
          <Col className='box-service serv3'>
            <div className='gradient-hover'>
              <h4>EMPLAYE DE MERCANCÍA</h4>
              <Button className='btn'>Saber más</Button>
            </div>
          </Col>
        </Row>
        <Row className='service-blocks second'>
          <Col className='box-service serv4'>
            <div className='gradient-hover'>
              <h4>ENVÍOS A OCURRE (CEDIS)</h4>
              <Button className='btn'>Saber más</Button>
            </div>
          </Col>
          <Col className='box-service serv5'>
            <div className='gradient-hover'>
              <h4>RETORNO DE EVIDENCIAS FÍSICAS O DIGITALES</h4>
              <Button className='btn'>Saber más</Button>
            </div>
          </Col>
          <Col className='box-service serv6'>
            <div className='gradient-hover'>
              <h4>UNIDADES ESPECIALIZADAS DE PAQUETERÍA</h4>
              <Button className='btn'>Saber más</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export function ImgTxtBanner({ section }) {
  const navigate = useNavigate();

  return (
    <Row
      className={`text-img-banner ${section === 3 ? 'section-3' : ''} ${
        section === 2 ? 'section-2' : ''
      }`}
    >
      <Row className='text-img-banner-row'>
        <Col
          className={section === 3 ? 'background-img' : ''}
          sm={12}
          md={6}
        ></Col>
        {section === 1 ? (
          <Col className='text-img-banner-row-section-1'>
            <p>Profesionales en Mensajería, Paquetería y LTL.</p>
            <p>
              Contamos con Cedis Operativos en Puebla, Ciudad de México,
              Querétaro y León. Comprometidos para satisfacer las necesidades de
              nuestros clientes, a través de un servicio confiable, seguro,
              rápido y amable.
            </p>
            <Button
              className='btn-light-blue'
              onClick={() => navigate('/contacto')}
            >
              Contáctanos
            </Button>
          </Col>
        ) : section === 2 ? (
          <Col className='text-img-banner-row-section-2'>
            <p>
              Cumpliendo con los mas altos estandares de calidad, contamos con
              los mejores
            </p>
            <h1>Protocolos de máxima seguridad</h1>
            <p>
              Nuestras unidades cuentan con sistema de rastreo GPS y monitoreo
              en tiempo real.
            </p>
            <Button
              className='btn-light-blue'
              onClick={() => navigate('/contacto')}
            >
              Contáctanos
            </Button>
          </Col>
        ) : (
          <Col className='text-img-banner-row-section-3' sm={12} md={6}>
            <div className='content'>
              <h2>Contamos con mas</h2>
              <h1>
                <span>de</span> 30 ubicaciones
              </h1>
              <h2>a tu disposicion</h2>
              <p className='first-p'>
                Presencia en <b>150 ciudades</b>
              </p>
              <p className='scnd-p'>Cubriendo el 70% del territorio nacional</p>
              <p className='thrd-p'>
                Nuestras unidades cuentan con sistema de rastreo GPS y monitoreo
                en tiempo real
              </p>
              <Button
                className='btn-light-blue'
                onClick={() => navigate('/cobertura')}
              >
                Ver destinos
              </Button>
            </div>
          </Col>
        )}
      </Row>
    </Row>
  );
}

export function LargeFooter() {
  const navigate = useNavigate();

  return (
    <Row className='footer-large'>
      {/* <Col></Col> */}
      <Col className='footer-large-col-2-main' md={3} sm={12}>
        <img src={logoTransparent} alt='' />
        {/* <h2>(Logo)</h2> */}
        <p className='footer-large-col-1--main-text'>
          Mensajeria, Paquetería y LTL. Contamos con Cedis Operativos en Puebla,
          Ciudad de México, Querétaro y León. Comprometidos para satisfacer las
          necesidades de nuestros clientes, a través de un servicio confiable,
          seguro, rápido y amable.
        </p>
        <div className='footer-large-col-1'>
          <div className='icon'>
            <FontAwesomeIcon icon={faLocationDot} />
          </div>
          <div className='icon-text'>
            <p className='no-margin'>Dirección</p>
            <p>No.100 Avenida, Colonia, Ciudad.</p>
          </div>
        </div>
        <div className='footer-large-col-1'>
          <div className='icon'>
            <FontAwesomeIcon icon={faPhone} />
          </div>
          <div className='icon-text'>
            <p className='no-margin'>Numero de Telefono</p>
            <p>987 654 321 | Atención al cliente</p>
          </div>
        </div>
        <div className='footer-large-col-1'>
          <div className='icon'>
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
          <div className='icon-text'>
            <p className='no-margin'>Email</p>
            <p>servicioaclientes@vdmlogistics.com.mx</p>
          </div>
        </div>
      </Col>
      <Col className='footer-large-col-2' sm={12} md={3}>
        <h4>Acceso Rápido</h4>
        <span className='blue-line-decorator'></span>
        <ul>
          <li onClick={() => navigate('/')}>
            <FontAwesomeIcon icon={faArrowRight} />
            Inicio
          </li>
          <li onClick={() => navigate('/servicios')}>
            <FontAwesomeIcon icon={faArrowRight} />
            Servicios
          </li>
          <li onClick={() => navigate('/')}>
            <FontAwesomeIcon icon={faArrowRight} />
            Centro de ayuda
          </li>
          <li onClick={() => navigate('/')}>
            <FontAwesomeIcon icon={faArrowRight} />
            FAQs
          </li>
          <li onClick={() => navigate('/')}>
            <FontAwesomeIcon icon={faArrowRight} />
            Código de conducta
          </li>
          <li onClick={() => navigate('/')}>
            <FontAwesomeIcon icon={faArrowRight} />
            Blog
          </li>
          <li onClick={() => navigate('/')}>
            <FontAwesomeIcon icon={faArrowRight} />
            Términos y condiciones
          </li>
          <li onClick={() => navigate('/')}>
            <FontAwesomeIcon icon={faArrowRight} />
            Facturación
          </li>
        </ul>
      </Col>
      <Col className='footer-large-col-3' sm={12} md={3}>
        <h5>
          <b>Regístrate</b>{' '}
          <span>y recibe nuestras ofertas y recomendaciones:</span>
        </h5>
        <span className='blue-line-decorator'></span>
        <p>Compártenos tu nombre y correo electrónico</p>
        <Form className='form-tracking'>
          <InputGroup size='sm' className='mb-3 inputs-form'>
            <InputGroup.Text id='inputGroup-sizing-sm'>Nombre</InputGroup.Text>
            <Form.Control
              aria-label='Name'
              aria-describedby='inputGroup-sizing-sm'
            />
          </InputGroup>
          <InputGroup size='sm' className='mb-3 inputs-form'>
            <InputGroup.Text id='inputGroup-sizing-sm'>Email</InputGroup.Text>
            <Form.Control
              aria-label='Email'
              aria-describedby='inputGroup-sizing-sm'
            />
          </InputGroup>
          <Button className='btn-light-blue'>Subscribirme</Button>
        </Form>
        <div className='social-media'>
          <h4>Síguenos en nuestras redes sociales</h4>
          <FontAwesomeIcon icon={faFacebook} />
          <FontAwesomeIcon icon={faSquareInstagram} />
          <FontAwesomeIcon icon={faLinkedin} />
        </div>
      </Col>
      {/* <Col></Col> */}
    </Row>
  );
}

export function SucursalesFooter() {
  return (
    <Row className='sucursales'>
      <Col md={3} sm={12}>
        <h5>Centro Operativo Puebla</h5>
        <p>
          Calle Insurgentes No.51 Col. Tepeyac, entre Fco. Javier Mina y
          Ayuntamiento, Puebla.
        </p>
        <p>CP. 72210</p>
        <p>Tel 22-29671647</p>
      </Col>
      <Col md={3} sm={12}>
        <h5>Centro Operativo México</h5>
        <p>
          Calle Norte 1-K No 4609 Col. Defensores de la República Entre Poniente
          106 y Poniente 108 Alcaldia Gustavo A. Madero, CDMX
        </p>
        <p>CP. 07790</p>
        <p>Tel 55-94181394</p>
      </Col>
      <Col md={3} sm={12}>
        <h5>Centro Operativo Queretaro</h5>
        <p>
          Calle Hacienda Agua Azul No 2 Col. Galerias Esq. Carr Querétaro -
          Tequisquiapan Colón, Querétaro.
        </p>
        <p>CP. 76295</p>
        <p>Tel 44-29800074</p>
      </Col>
      <Col md={3} sm={12}>
        <h5>Centro Operativo León</h5>
        <p>
          Calle Gamna No 102-B Col. Industrial Delta Entre Blvd. Delta y Tau
          León, Guanajuato
        </p>
        <p>CP. 37545</p>
        <p>Tel 47-79801657</p>
      </Col>
    </Row>
  );
}

export function MiniFooter() {
  return (
    <Row className='mini-footer'>
      <Col className='copyright'>
        <p>
          <b>VDM Logistics 2023</b> | Todos los derechos reservados
        </p>
      </Col>
      <Col className='menu-footer'>
        <Col>
          <Nav.Link href={'/'}>Inicio</Nav.Link>
        </Col>
        <Col>
          <Nav.Link href={'/servicios'}>Servicios</Nav.Link>
        </Col>
        <Col>
          <Nav.Link href={'/cobertura'}>Cobertura</Nav.Link>
        </Col>
        <Col>
          <Nav.Link href={'/rastreo'}>Rastreo</Nav.Link>
        </Col>
        <Col>
          <Nav.Link href='#action2' disabled style={{ color: 'gray' }}>
            Cotizador
          </Nav.Link>
        </Col>
        <Col>
          <Nav.Link href={'/contacto'}>Contacto</Nav.Link>
        </Col>
        {/* <Col>Inicio</Col>
        <Col>Servicios</Col>
        <Col>Cobertura</Col>
        <Col>Rastreo</Col>
        <Col>Cotizador</Col>
        <Col>Contacto</Col> */}
        <Col>{/* <Nav.Link href='#'>^</Nav.Link> */}</Col>
      </Col>
    </Row>
  );
}

function Home() {
  return (
    <div className='vdm-app'>
      <Helmet>
        <title>VDM | Inicio</title>
        {/* <meta
          name='description'
          content='Beginner friendly page for learning React Helmet.'
        /> */}
      </Helmet>
      <MiniHeader />
      <NavScrollExample />
      <MainBanner />
      <MiniBanner count={5} />
      <TextImgBanner section={1} />
      <ServiceHomeSection />
      <ImgTxtBanner section={2} />
      <MiniBanner count={4} />
      <ImgTxtBanner section={3} />
      <LargeFooter />
      <SucursalesFooter />
      <hr style={{ margin: 0, opacity: 0.9 }} />
      <MiniFooter />
      {/* form 4tracking */}
      {/* <div className="body-container">
            VDM App - {err}
            <div className="form-container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input {...register("trackingNumber")} />
                    <input type="submit" text="tracking" />
                </form>
            </div>
        </div> */}
    </div>
  );
}

export default Home;
