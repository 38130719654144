import React from 'react';
import {
  MiniHeader,
  NavScrollExample,
  LargeFooter,
  SucursalesFooter,
  MiniFooter,
} from './Home.jsx';
import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import camion from '../../assets/Apartado-Cobertura/Mockup_of_a_front_van_isolated.png';
import mapa from '../../assets/Apartado-Cobertura/van.png';
import logoTransparent from '../../assets/Logo-azul-trans.png';
import '../../styles/Home.scss';
import { Helmet } from 'react-helmet';
import '../../styles/Cobertura.scss';
import { useForm } from 'react-hook-form';

function Cobertura() {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    console.log('input: ', data.packageID);
    const trackingNumber = data.packageID;
    navigate(`/rastreo-details/${trackingNumber}`);
  };
  return (
    <div>
      <Helmet>
        <title>VDM | Cobertura</title>
        {/* <meta
          name='description'
          content='Beginner friendly page for learning React Helmet.'
        /> */}
      </Helmet>
      <MiniHeader />
      <NavScrollExample />
      <Row className='row-main-banner cobertura'>
        <Col md={6} sm={12} className='right-panel cobertura'>
          <h2>LLEGAMOS A DONDE TU ESTES</h2>
          <p>
            <b>Llegamos a donde otros no pueden:</b>
            <br />
            VDM te brinda acceso a una amplia red de distribución para atender
            tus necesidades logísticas
          </p>
          <div className='btns'>
            <Button
              className='btn-light-blue'
              onClick={() => navigate('/contacto')}
            >
              Contáctanos
            </Button>
          </div>
        </Col>
        <Col md={4} sm={12}>
          {/* <h3>Rastreo de paquete</h3>
          <p className='capitals'>
            Para REALIZAR LA BÚSQUEDA Ingrese EL CÓDIGO DE RAsTREO DE SU ENVÍO
          </p>
          <p style={{ color: '#677fbc' }}>
            Ubicado en la parte superior de su factura
          </p>
          <Form className='form-tracking' onSubmit={handleSubmit(onSubmit)}>
            <Form.Control
              placeholder='Ejemplo: ABC-1233300'
              aria-label='Search'
              {...register('packageID')}
            />
            <Button className='btn-blue' type='submit'>
              Rastrear
            </Button>
            <a className='notfount-link' href='#'>
              No lo encuentro
            </a>
          </Form> */}
        </Col>
      </Row>

      <Row className='text-img-banner-cobertura'>
        <Col className='text-container' md={6} sm={12}>
          <div className='content'>
            <img src={logoTransparent} alt='' />
            <h4>
              Presencia en <b>150 ciudades</b>
            </h4>
            <h2>Cubriendo el 70% del territorio nacional</h2>
            {/* <p className='description-extra'>Lorem ipsum</p> */}
          </div>
        </Col>
        <Col md={6} sm={12}>
          {/* <div className='map-img'></div>
          <div className='camion-img'></div> */}
          <img className='map-img' src={mapa} alt='' />
          {/* <img className='camion-img' src={camion} alt='' /> */}
        </Col>
      </Row>

      <Row className='blue-banner'>
        <h1>
          DESTINOS <b>PRINCIPALES</b>
        </h1>
      </Row>

      <Row className='list-of-cities'>
        <Col className='list-col-1 list-col' md={3} sm={12}>
          <div style={{ background: '#FBFBFB' }}>
            <h4>Ciudad de mexico</h4>
            <ul>
              <li>Alvaro Obregon</li>
              <li>Azcapotzalco</li>
              <li>Benito Juarez</li>
              <li>Coyoacan</li>
              <li>Cuahutemoc</li>
              <li>Iztacalco</li>
              <li>Iztapalapa</li>
              <li>Miguel Hidalgo</li>
              <li>Tlalpan</li>
              <li>Venustiano Carranza</li>
            </ul>
          </div>
          <div>
            <h4>Queretaro</h4>
            <ul>
              <li>Qeuretaro</li>
              <li>Corregidora</li>
              <li>El Marques</li>
              <li>Jiriquilla</li>
            </ul>
          </div>
          <div style={{ background: '#FBFBFB' }}>
            <h4>Veracruz</h4>
            <ul>
              <li>Veracruz</li>
              <li>Boca del rio</li>
              <li>Xalapa</li>
              <li>Córdoba</li>
              <li>Orizaba</li>
              <li>Perote</li>
            </ul>
          </div>
        </Col>
        <Col className='list-col-2 list-col' md={3} sm={12}>
          <div>
            <h4>Edo Mex</h4>
            <ul>
              <li>Toluca</li>
              <li>Lerma</li>
              <li>Metepec</li>
              <li>Atizapan</li>
              <li>Atizapan de Zaragoza</li>
              <li>Naucalpan</li>
              <li>Tlalnepantla</li>
              <li>Tepotzotlán</li>
              <li>Cuautitlán Izcalli</li>
              <li>Cuautitlán</li>
              <li>Tultitlán</li>
              <li>Ecatepec</li>
              <li>Coacalco</li>
              <li>Nezahualcóyotl</li>
              <li>Zinacantepec</li>
              <li>San Mateo Atenco</li>
            </ul>
          </div>
          <div style={{ background: '#FBFBFB' }}>
            <h4>Aguascalientes</h4>
            <ul>
              <li>Aguascalientes</li>
              <li>Jesús María</li>
            </ul>
          </div>
          <div>
            <h4>Morelos</h4>
            <ul>
              <li>Cuernavaca</li>
              <li>Jiutepec</li>
            </ul>
          </div>
        </Col>
        <Col className='list-col-3 list-col' md={3} sm={12}>
          <div style={{ background: '#FBFBFB' }}>
            <h4>Nuevo Leon</h4>
            <ul>
              <li>Monterrey</li>
              <li>Santa Catarina</li>
              <li>San Pedro Garza García</li>
              <li>San Nicolás de los Garza</li>
              <li>Apodaca</li>
              <li>Guadalupe</li>
              <li>Gral. Escobedo</li>
            </ul>
          </div>
          <div>
            <h4>SAN LUIS POTOSI</h4>
            <ul>
              <li>San Luis Potosí</li>
              <li>Soledad de Graciano</li>
              <li>Sanchez</li>
            </ul>
          </div>
          <div style={{ background: '#FBFBFB' }}>
            <h4>Jalisco</h4>
            <ul>
              <li>Guadalajara</li>
              <li>Zapopán</li>
              <li>Tonalá</li>
              <li>San Pedro Tlaquepaque</li>
            </ul>
          </div>
          <div>
            <h4>Hidalgo</h4>
            <ul>
              <li>Pachuca</li>
              <li>Mineral de la Reforma</li>
            </ul>
          </div>
        </Col>
        <Col className='list-col-4 list-col' md={3} sm={12}>
          <div>
            <h4>Tlaxcala</h4>
            <ul>
              <li>Tlaxcala</li>
              <li>Huamantla</li>
              <li>Apizaco</li>
              <li>San Pablo del Monte</li>
              <li>Papalotla</li>
              <li>Xicohtzingo</li>
              <li>Zacatelco</li>
              <li>Tenancingo</li>
              <li>Santa Ana Chiautempan</li>
            </ul>
          </div>
          <div style={{ background: '#FBFBFB' }}>
            <h4>Puebla</h4>
            <ul>
              <li>Puebla</li>
              <li>San Pedro Cholula</li>
              <li>San Andrés Cholula</li>
              <li>Cuautlancingo</li>
              <li>Coronango</li>
              <li>San Miguel Xoxtla</li>
            </ul>
          </div>
          <div>
            <h4>Guanajuato</h4>
            <ul>
              <li>León</li>
              <li>Silao</li>
              <li>Irapuato</li>
              <li>Celaya</li>
              <li>Salamanca</li>
            </ul>
          </div>
        </Col>
      </Row>

      <Row className='delivery-img'></Row>

      <Row className='light-blue-banner'>
        <h1>
          DESTINOS <b>EXTENDIDOS</b>
        </h1>
      </Row>

      <Row className='list-of-cities'>
        <Col className='list-col-1 list-col'>
          <div style={{ background: '#FBFBFB' }}>
            <h4>CIUDAD DE MÉXICO</h4>
            <ul>
              <li>Cuajimalpa</li>
              <li>La Magdalena C.</li>
              <li>Tláhuac</li>
              <li>Xochimilco</li>
            </ul>
          </div>
          <div>
            <h4>Queretaro</h4>
            <ul>
              <li>Cadereyta de Montes</li>
              <li>Colón</li>
              <li>Tolimán</li>
              <li>Ezequiel Montes</li>
              <li>Pedro Escobedo</li>
              <li>Tequisquiapan</li>
              <li>Amealco de Bonfil</li>
              <li>Huimilpan</li>
              <li>San Juan del Río</li>
            </ul>
          </div>
          <div style={{ background: '#FBFBFB' }}>
            <h4>Guanajuato</h4>
            <ul>
              <li>Irapuato</li>
              <li>Celaya</li>
              <li>Salamanca</li>
            </ul>
          </div>
          <div>
            <h4>Morelos</h4>
            <ul>
              <li>Cuautla</li>
              <li>Yautepec</li>
              <li>Oaxtepec</li>
              <li>Xochitepec</li>
              <li>Temixco</li>
            </ul>
          </div>
        </Col>
        <Col className='list-col-2 list-col'>
          <div>
            <h4>Estado Mexico</h4>
            <ul>
              <li>Calimaya</li>
              <li>Tenango del Valle</li>
              <li>Tianguistenco</li>
              <li>Nicolas Romero</li>
              <li>Tultepec</li>
              <li>Atenco</li>
              <li>Chalco</li>
              <li>Ixtapaluca</li>
              <li>Chimalhuacán</li>
              <li>Ocoyoacac</li>
              <li>Texcoco</li>
            </ul>
          </div>
          <div style={{ background: '#FBFBFB' }}>
            <h4>Aguascalientes</h4>
            <ul>
              <li>illa de Reyes</li>
              <li>Cerro de San Pedro</li>
              <li>Ahualulco</li>
              <li>Mexquitic de Carmona</li>
              <li>Vanegas</li>
              <li>Cedral</li>
              <li>Catorce</li>
              <li>Charcas</li>
            </ul>
          </div>
          <div>
            <h4>TLAXCALA</h4>
            <ul>
              <li>Calpulalpan</li>
              <li>Hueyotlipan</li>
              <li>Nanacamilpa</li>
              <li>Tlaxco</li>
            </ul>
          </div>
        </Col>
        <Col className='list-col-3 list-col'>
          <div style={{ background: '#FBFBFB' }}>
            <h4>NUEVO LEON</h4>
            <ul>
              <li>Allende</li>
              <li>Montemorelos</li>
              <li>Linares</li>
              <li>Gral. Zaragoza</li>
            </ul>
          </div>
          <div>
            <h4>VERACRUZ</h4>
            <ul>
              <li>Acayucan</li>
              <li>Martínez de la Torre</li>
              <li>Tierra Blanca</li>
              <li>José Cardel</li>
              <li>Cosamaloapan</li>
              <li>San Andrés Tuxtla</li>
              <li>La Tinaja</li>
              <li>Ciudad Mendoza</li>
              <li>Coscomatepec</li>
              <li>Huatusco</li>
              <li>Tlapacoyan</li>
              <li>San Rafael</li>
              <li>Papantla</li>
              <li>Coatzacoalcos</li>
              <li>Poza Rica</li>
              <li>Tuxpan</li>
              <li>MinatitláXicotepec</li>
              <li>de Juárez</li>
            </ul>
          </div>
          <div style={{ background: '#FBFBFB' }}>
            <h4>AGUASCALIENTES</h4>
            <ul>
              <li>Alvillo</li>
              <li>Rincón de los Romo</li>
              <li>Pabellón de Arteaga</li>
              <li>San Francisco de los Romo</li>
            </ul>
          </div>
        </Col>
        <Col className='list-col-4 list-col'>
          <div>
            <h4>TLAXCALA</h4>
            <ul>
              <li>Calpulalpan</li>
              <li>Hueyotlipan</li>
              <li>Nanacamilpa</li>
              <li>Tlaxco</li>
            </ul>
          </div>
          <div style={{ background: '#FBFBFB' }}>
            <h4>Puebla</h4>
            <ul>
              <li>Acatzingo</li>
              <li>Acajete</li>
              <li>Amozoc</li>
              <li>Tepeaca</li>
              <li>San Martín Texmelucan</li>
              <li>Huejotzingo</li>
              <li>Palmar de Bravo</li>
              <li>Teziutlán</li>
              <li>Huauchinango</li>
              <li>Libres</li>
              <li>Lara Grajales</li>
              <li>Ciudad Serdán</li>
              <li>Zacatlán</li>
              <li>Chignahuapan</li>
              <li>Tecamachalco</li>
              <li>Zacapoaxtla</li>
              <li>Atlixco</li>
              <li>Izúcar de Matamoros</li>
              <li>Acatlán de Osorio</li>
              <li>Tecali de Herrera</li>
              <li>Oriental</li>
              <li>San José Chiapa</li>
              <li>Zaragoza</li>
              <li>Xicotepec de Juárez</li>
            </ul>
          </div>
        </Col>
      </Row>

      <Row className='hands-img'></Row>
      {/* <LargeFooter /> */}
      <SucursalesFooter />
      <hr style={{ margin: 0, opacity: 0.9 }} />
      <MiniFooter />
    </div>
  );
}

export default Cobertura;
