import React from 'react';
import ReactDOM from 'react-dom/client';
import '../src/styles/global_styles.scss';
import Home from '../src/components/pages/Home.jsx';
import Contact from '../src/components/pages/Contact';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cobertura from './components/pages/Cobertura';
import Servicios from './components/pages/Servicios';
import Rastreo from './components/pages/Rastreo';
import RastreoDetails from './components/pages/RastreoDetails';
import ErrorBoundary from './components/pages/ErrorBoundary';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/contacto',
    element: <Contact />,
  },
  {
    path: '/cobertura',
    element: <Cobertura />,
  },
  {
    path: '/servicios',
    element: <Servicios />,
  },
  {
    path: '/rastreo',
    element: <Rastreo />,
  },
  {
    path: '/rastreo-details/:packageid',
    element: <RastreoDetails />,
    errorElement: <ErrorBoundary />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
